<template>
  <v-dialog v-model="dialog"
            width="1000px">
    <v-card>
      <v-card-title>
        <span class="headline"> {{ $tc('label.termo_aceite') }}</span>
      </v-card-title>
      <v-card-text v-html="html">
      </v-card-text>
      <v-card-actions>
        <v-radio-group v-model="indAceito" row>
          <v-radio
            :label="`${$tc('label.li_concordo_termos_condicoes')}`"
            :value="true"
          ></v-radio>
        </v-radio-group>
        <v-spacer></v-spacer>
        <v-btn id="cancel" color="primary darken-1" @click.native="close">
          {{ $t('label.fechar') }}
        </v-btn>
        <v-btn
          id="finalizar"
          color="primary"
          :disabled="!indAceito"
          text @click.native="finalizar">
          {{ $t('label.finalizar') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'UsuarioFormTermoAceiteDialog',
  components: {
  },
  props: {
    termoAceite: Object,
  },
  data() {
    return {
      dialog: false,
      html: null,
      indAceito: false,
    };
  },
  computed: {
  },
  methods: {
    open(email, razaoSocial, nomeUsuarioCriado) {
      this.dialog = true;
      this.html = this.substituirValores(this.termoAceite.conteudo, email, razaoSocial, nomeUsuarioCriado);
    },

    close() {
      this.dialog = false;
    },
    substituirValores(html, email, razaoSocial, nomeUsuarioCriado) {
      const valores = {
        '[RAZAO_SOCIAL]': razaoSocial,
        '[NOME_COMPLETO_USUARIO_CRIADO]': nomeUsuarioCriado,
        '[EMAIL]': email,
      };
      Object.entries(valores).forEach(([chave, valor]) => {
        html = html.replaceAll(chave, valor);
      });
      return html;
    },
    finalizar() {
      this.termoAceite.ind_aceito = this.indAceito;
      this.dialog = false;
      this.$emit('Finalizar');
    },
  },
};
</script>
